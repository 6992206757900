@import url(https://fonts.googleapis.com/css2?family=Poppins:wght@400;500;700&display=swap);
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: "Poppins", sans-serif;
  cursor: inherit;
}
body {
  background-color: #2e3544;

  /* --main-color: #0070f3 */

  /* --main-color:#edc139; */
  --main-color: #adc916;
}
html {
  font-size: 62.5%;
}
a {
  text-decoration: none;
  color: #fff;
}
section {
  position: relative;
  min-height: 100vh;
  overflow-x: hidden;
}

.container {
  position: relative;
  margin-top: 100px;
}

/* html
{
  font-size: 62.5%;
}

body {
  line-height: 1.65;
  font-family: "Inter", -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto",
    "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  font-size: 16px;
  font-weight: 400;
  direction: ltr;
  font-feature-settings: "kern";
  text-rendering: optimizeLegibility;
  margin: 0;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
} */

.full-load {
  position: fixed;
  left: 0;
  top: 0;
  background-color: #2e3544;
  width: 100vw;
  height: 100vh;
  z-index: 10001;
  box-shadow: 0 0 25px 15px rgba(0, 0, 0, 0.3);
}

.full-load .loader-text {
  position: relative;
  display: flex;
  color: #fff;
  z-index: 2000;
  justify-content: center;
  align-items: center;
  height: 100vh;
}
.full-load .loader-text h1 {
  overflow: hidden;
  font-size: 4rem;
  font-weight: 500;
}

.full-load .loader-text h1 span {
  position: relative;
  display: inline-block;
  transform: translateY(100%);
  margin-right: 10px;
  opacity: 0;
}

.full-load .loader-text h1 span:first-of-type::after {
  content: "";
  position: absolute;

  bottom: 2px;
  left: 3px;
  width: 90%;
  height: 2px;
  background-color: var(--main-color);
  transition: 0.1s linear;
}
/* 
.page-enter {
  opacity: 0;
}
.page-enter-active {
  opacity: 1;
  transition: opacity 400ms;
  transition-delay: 600ms;
}
.page-exit {
  opacity: 1;
}
.page-exit-active {
  opacity: 0;
  transition: opacity 400ms;
}

/* .App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
} */

header {
  /* background-color: #000; */
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  padding: 20px 100px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  z-index: 10;
}

input[type="checkbox"] {
  display: none;
}
input[type="checkbox"]:checked ~ .bar {
  transform: rotate(45deg);
  top: 0;
}

input[type="checkbox"]:checked ~ .bar:before {
  transform: rotate(-90deg);
  top: 0;
}

header .logo {
  color: #fff;
  font-size: 1.2rem;
  font-weight: 500;
}

header .logo svg {
  width: 80px;
  height: 80px;
  fill: #fff;
}

header .logo svg .cls-1 {
  fill: var(--main-color);
}

header ul {
  display: flex;
  /* display: none; */
}

header ul li {
  list-style: none;
  margin: 0 1rem 0 1rem;
}

header ul li a {
  position: relative;
  text-decoration: none;
  color: #fff;
  font-size: 1.5rem;
  font-weight: 500;
}

header ul li a::before {
  content: "";
  position: absolute;
  bottom: -2px;
  left: 0;
  width: 0;
  height: 2px;
  background-color: var(--main-color);
  transition: 0.1s linear;
}

header ul li a:hover::before,
header ul li a:focus::before,
header ul li a:active::before,
header ul li a.Selected::before {
  width: 60%;
}

.bar {
  display: none;
  position: relative;
  top: 5px;
  width: 30px;
  height: 2px;
  background-color: #fff;
}

.bar:before {
  /* display: none; */
  content: "";
  position: absolute;
  top: -10px;
  width: 21px;
  height: 2px;
  background-color: #fff;
}

.Selected {
  color: var(--main-color);
  text-shadow: 0px 0px 1px var(--main-color);
}

@media screen and (max-width: 1000px) {
  header {
    padding: 20px 10px;
    /* display: none; */
  }
}

.spinner {
  margin: 200px auto;
  width: 40px;
  height: 40px;
  position: relative;
}

.cube2 {
  background-color: var(--main-color);
}
.cube1 {
  background-color: var(--main-color);
}
.cube1,
.cube2 {
  width: 15px;
  height: 15px;
  position: absolute;
  top: 0;
  left: 0;
  border-radius: 50%;
  -webkit-animation: sk-cubemove 1.8s infinite ease-in-out;
  animation: sk-cubemove 1.8s infinite ease-in-out;
}

.cube2 {
  -webkit-animation-delay: -0.9s;
  animation-delay: -0.9s;
}

@-webkit-keyframes sk-cubemove {
  25% {
    -webkit-transform: translateX(42px) rotate(-90deg) scale(0.5);
  }
  50% {
    -webkit-transform: translateX(42px) translateY(42px) rotate(-180deg);
  }
  75% {
    -webkit-transform: translateX(0px) translateY(42px) rotate(-270deg)
      scale(0.5);
  }
  100% {
    -webkit-transform: rotate(-360deg);
  }
}

@keyframes sk-cubemove {
  25% {
    transform: translateX(42px) rotate(-90deg) scale(0.5);
    -webkit-transform: translateX(42px) rotate(-90deg) scale(0.5);
  }
  50% {
    transform: translateX(42px) translateY(42px) rotate(-179deg);
    -webkit-transform: translateX(42px) translateY(42px) rotate(-179deg);
  }
  50.1% {
    transform: translateX(42px) translateY(42px) rotate(-180deg);
    -webkit-transform: translateX(42px) translateY(42px) rotate(-180deg);
  }
  75% {
    transform: translateX(0px) translateY(42px) rotate(-270deg) scale(0.5);
    -webkit-transform: translateX(0px) translateY(42px) rotate(-270deg)
      scale(0.5);
  }
  100% {
    transform: rotate(-360deg);
    -webkit-transform: rotate(-360deg);
  }
}

.sk-chase {
  margin: 200px auto;
  width: 40px;
  height: 40px;
  position: relative;
  -webkit-animation: sk-chase 2.5s infinite linear both;
          animation: sk-chase 2.5s infinite linear both;
}

.sk-chase-dot {
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
  -webkit-animation: sk-chase-dot 2s infinite ease-in-out both;
          animation: sk-chase-dot 2s infinite ease-in-out both;
}

.sk-chase-dot:before {
  content: "";
  display: block;
  width: 25%;
  height: 25%;
  background-color: var(--main-color);
  border-radius: 100%;
  -webkit-animation: sk-chase-dot-before 2s infinite ease-in-out both;
          animation: sk-chase-dot-before 2s infinite ease-in-out both;
}

.sk-chase-dot:nth-child(1) {
  -webkit-animation-delay: -1.1s;
          animation-delay: -1.1s;
}
.sk-chase-dot:nth-child(2) {
  -webkit-animation-delay: -1s;
          animation-delay: -1s;
}
.sk-chase-dot:nth-child(3) {
  -webkit-animation-delay: -0.9s;
          animation-delay: -0.9s;
}
.sk-chase-dot:nth-child(4) {
  -webkit-animation-delay: -0.8s;
          animation-delay: -0.8s;
}
.sk-chase-dot:nth-child(5) {
  -webkit-animation-delay: -0.7s;
          animation-delay: -0.7s;
}
.sk-chase-dot:nth-child(6) {
  -webkit-animation-delay: -0.6s;
          animation-delay: -0.6s;
}
.sk-chase-dot:nth-child(1):before {
  -webkit-animation-delay: -1.1s;
          animation-delay: -1.1s;
}
.sk-chase-dot:nth-child(2):before {
  -webkit-animation-delay: -1s;
          animation-delay: -1s;
}
.sk-chase-dot:nth-child(3):before {
  -webkit-animation-delay: -0.9s;
          animation-delay: -0.9s;
}
.sk-chase-dot:nth-child(4):before {
  -webkit-animation-delay: -0.8s;
          animation-delay: -0.8s;
}
.sk-chase-dot:nth-child(5):before {
  -webkit-animation-delay: -0.7s;
          animation-delay: -0.7s;
}
.sk-chase-dot:nth-child(6):before {
  -webkit-animation-delay: -0.6s;
          animation-delay: -0.6s;
}

@-webkit-keyframes sk-chase {
  100% {
    transform: rotate(360deg);
  }
}

@keyframes sk-chase {
  100% {
    transform: rotate(360deg);
  }
}

@-webkit-keyframes sk-chase-dot {
  80%,
  100% {
    transform: rotate(360deg);
  }
}

@keyframes sk-chase-dot {
  80%,
  100% {
    transform: rotate(360deg);
  }
}

@-webkit-keyframes sk-chase-dot-before {
  50% {
    transform: scale(0.7);
  }
  100%,
  0% {
    transform: scale(0.4);
  }
}

@keyframes sk-chase-dot-before {
  50% {
    transform: scale(0.7);
  }
  100%,
  0% {
    transform: scale(0.4);
  }
}

