header {
  /* background-color: #000; */
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  padding: 20px 100px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  z-index: 10;
}

input[type="checkbox"] {
  display: none;
}
input[type="checkbox"]:checked ~ .bar {
  transform: rotate(45deg);
  top: 0;
}

input[type="checkbox"]:checked ~ .bar:before {
  transform: rotate(-90deg);
  top: 0;
}

header .logo {
  color: #fff;
  font-size: 1.2rem;
  font-weight: 500;
}

header .logo svg {
  width: 80px;
  height: 80px;
  fill: #fff;
}

header .logo svg .cls-1 {
  fill: var(--main-color);
}

header ul {
  display: flex;
  /* display: none; */
}

header ul li {
  list-style: none;
  margin: 0 1rem 0 1rem;
}

header ul li a {
  position: relative;
  text-decoration: none;
  color: #fff;
  font-size: 1.5rem;
  font-weight: 500;
}

header ul li a::before {
  content: "";
  position: absolute;
  bottom: -2px;
  left: 0;
  width: 0;
  height: 2px;
  background-color: var(--main-color);
  transition: 0.1s linear;
}

header ul li a:hover::before,
header ul li a:focus::before,
header ul li a:active::before,
header ul li a.Selected::before {
  width: 60%;
}

.bar {
  display: none;
  position: relative;
  top: 5px;
  width: 30px;
  height: 2px;
  background-color: #fff;
}

.bar:before {
  /* display: none; */
  content: "";
  position: absolute;
  top: -10px;
  width: 21px;
  height: 2px;
  background-color: #fff;
}

.Selected {
  color: var(--main-color);
  text-shadow: 0px 0px 1px var(--main-color);
}

@media screen and (max-width: 1000px) {
  header {
    padding: 20px 10px;
    /* display: none; */
  }
}
