.full-load {
  position: fixed;
  left: 0;
  top: 0;
  background-color: #2e3544;
  width: 100vw;
  height: 100vh;
  z-index: 10001;
  box-shadow: 0 0 25px 15px rgba(0, 0, 0, 0.3);
}

.full-load .loader-text {
  position: relative;
  display: flex;
  color: #fff;
  z-index: 2000;
  justify-content: center;
  align-items: center;
  height: 100vh;
}
.full-load .loader-text h1 {
  overflow: hidden;
  font-size: 4rem;
  font-weight: 500;
}

.full-load .loader-text h1 span {
  position: relative;
  display: inline-block;
  transform: translateY(100%);
  margin-right: 10px;
  opacity: 0;
}

.full-load .loader-text h1 span:first-of-type::after {
  content: "";
  position: absolute;

  bottom: 2px;
  left: 3px;
  width: 90%;
  height: 2px;
  background-color: var(--main-color);
  transition: 0.1s linear;
}
/* 
.page-enter {
  opacity: 0;
}
.page-enter-active {
  opacity: 1;
  transition: opacity 400ms;
  transition-delay: 600ms;
}
.page-exit {
  opacity: 1;
}
.page-exit-active {
  opacity: 0;
  transition: opacity 400ms;
}

/* .App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
} */
