@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@400;500;700&display=swap");

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: "Poppins", sans-serif;
  cursor: inherit;
}
body {
  background-color: #2e3544;

  /* --main-color: #0070f3 */

  /* --main-color:#edc139; */
  --main-color: #adc916;
}
html {
  font-size: 62.5%;
}
a {
  text-decoration: none;
  color: #fff;
}
section {
  position: relative;
  min-height: 100vh;
  overflow-x: hidden;
}

.container {
  position: relative;
  margin-top: 100px;
}

/* html
{
  font-size: 62.5%;
}

body {
  line-height: 1.65;
  font-family: "Inter", -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto",
    "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  font-size: 16px;
  font-weight: 400;
  direction: ltr;
  font-feature-settings: "kern";
  text-rendering: optimizeLegibility;
  margin: 0;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
} */
